.home-main .content .chat-wrapper {
  max-width: 680px;
  margin: 0 auto;
  padding-top: 20px;
}

.chat-main .chat-content {
  background: white;
  min-height: 85vh;
  max-height: 85vh;
  overflow-y: auto;
  padding: 10px;
  border-radius: 20px;
}

.chat-main .chat-wrapper .text {
  margin: 0px;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
  padding: 9px;
  border-radius: 12px;
}

.chat-main .oracle .text {
  display: inline-flex;
  background: #f2f2f2;
  align-items: center;
}

.chat-main .oracle .text svg {
  width: 36px;
}

.chat-main .msg-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.chat-main .human-wrapper {
  justify-content: flex-end;
}


.chat-main .human .text {
  color: white;
  background: #5c7cfa;
}

.chat-main .msg-item {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.chat-main .msg-item img {
  width: 32px;
  height: 32px;
}

.chat-main .msg-item .icon-wraper {
  padding: 0px 9px;
}

.human.msg-item .text {
  margin-left: 50px;
}

.oracle.msg-item .text {
  margin-right: 50px;
}

.chat-main .msg-send {
  margin-top: 15px;
}

.chat-main .send-wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  background: white;
  border-radius: 12px;
  padding: 0.5em;
}

.chat-main .msg {
  width: 100%;
  max-height: 200px;
  height: 24px;
  overflow-y: hidden;

  font-size: 15px;
  font-weight: 500;
  border: none;
}

.chat-main .msg:focus {
  outline: none;
}

.chat-main .send {
  margin-left: 0.5em;
  cursor: pointer;
  padding: 6px 5px 1px 4px;
  border-radius: 25px;
}


.chat-main .send:hover {
  background: #f2f2f2;
}

.chat-main .send img {
  width: 24px;
}