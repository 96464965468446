html, body {
  margin: 0;
  padding: 0;
}



.home-main {
  position: relative;
  width: 100%;
  height: 100%;
}

.home-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.home-main .content {
  padding-left: 260px;
  min-height: 100vh;
  background-color: #f2f2f2;
}
